import type { MutableRefObject } from 'react'
import { useEffect } from 'react'

type UseOnClickOutsideOptions = {
  runOnEscape?: boolean
}

export const useClickOutside = (ref: MutableRefObject<HTMLDivElement | null>, handler: () => void, options?: UseOnClickOutsideOptions) => {
  const { runOnEscape = true } = options || {}

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent | KeyboardEvent) => {
      if (runOnEscape && 'key' in event && event.key === 'Escape') {
        handler()
        return
      }

      if (!ref.current || 'key' in event) {
        return
      }

      if (ref.current.contains(event.target as Node)) {
        return
      }

      handler()
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    document.addEventListener('keydown', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
      document.removeEventListener('keydown', listener)
    }
  }, [ref, handler, runOnEscape])
}
